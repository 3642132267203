import { ACTION_TYPES } from './actionTypes';


export const setToken = (token) => {
	console.log('setSSOToken', token);
	return (dispatch) => {
		dispatch({		
			type: ACTION_TYPES.SET_SSO_TOKEN,
			payload: token			
		})
	}
} 
