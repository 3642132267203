import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import InputField from '../../components/input-field';
import withTranslate from '../hoc/withTranslate';
import Button from '../../components/button';
import "./discussion-form.scss"
import history from '../../routes/history';
import * as authoringDispatcher from '../../actions/authoringDispatcher';
import _ from "lodash"
import Loader from '../../components/loader';
import Dropdown from '../../components/dropdown';

export class DiscussionForm extends Component {
  state = {
    discussionUrn: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.discussionUrn : "",
    stimulus: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.stimulus : "",
    question: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.question : "",
    sampleAnswer: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.sampleAnswer : "",
    title: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.title : "",
    subTitle: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.subTitle : "",
    lineOfBusiness: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.lineOfBusiness : "",
    smartLink: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.smartLink : "",
    allError: {
      title: false,
    },
    isSuccessfull: false,
    error: null, 
    isLoading: false
  }

  componendDidMount(){
    const {formData, token, actions} = this.props;   
    if (!token) {
      this.setState({isLoading: true});
      //actions.getSSOToken();
    }else {
      this.setState({
        discussionUrn: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.discussionUrn : "",
        stimulus: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.stimulus : "",
        question: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.question : "",
        sampleAnswer: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.sampleAnswer : "",
        title: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.title : "",
        subtitle: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.subTitle : "",
        lineOfBusiness: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.lineOfBusiness : "",
        smartLink: !_.isEmpty(this.props.formData.formData) ? this.props.formData.formData.smartLink : ""
      });
      
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.error && state.error) {
      return {isSuccessfull: false, error: props.error}
    } else if (!state.sso && props.sso || (state.sso != props.sso)) {
     return {isLoading: false};
    } else if (!props.lineOfBusinesses) {
      return {lineOfBusinesses: []}
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState){
    // console.log('prevProps.isSuccessfull', prevProps.isSuccessfull, '  --this.props.isSuccessfull:::', this.props.isSuccessfull)
    if (prevProps.isSuccessfull != this.props.isSuccessfull) {
      this.setState({ isLoading: false});
      history.push('/discussion-url')
    } else if (!this.state.error && this.props.error) {
      this.setState({error: this.props.error})
    } else if (this.props.lineOfBusinesses.length == 0) {
    	this.props.actions.getAllLinesOfBusiness();
    } 
  }


  onChangeTextArea = (input, event) => {
    switch (input) {
      case 'title':
        this.setState({
          title: event.target.value,
          allError: { ...this.state.allError, title: !event.target.value && true }
        })
        break;
      case 'subTitle':
        this.setState({
          subTitle: event.target.value,
        })
        break;
      case 'stimulus':
        this.setState({
          stimulus: event.target.value,
        })
        break;
      case 'question':
        this.setState({
          question: event.target.value,
        })
        break;
      case 'sampleAnswer':
        this.setState({
          sampleAnswer: event.target.value,
        })
        break;
      default:
        break;
    }
  }


  onSave = () => {
    const { stimulus, question, sampleAnswer, title, subTitle, discussionUrn, lineOfBusiness, smartLink } = this.state;
    const actionData = {
      stimulus,
      question,
      sampleAnswer,
      title,
      subTitle,
      discussionUrn,
      lineOfBusiness,
      smartLink
    }

    this.setState({isSuccessful: false}, () => {
      this.props.actions.saveFormData(actionData);
      const {actions, token} = this.props;
      if (actionData.discussionUrn && actionData.discussionUrn.length >= 0) {
        actions.saveDiscussion(actionData, token);
      }else {
        actions.submitDiscussion(actionData, token);
      }
    })
  }

  onPreview = () => {
    const { stimulus, question, sampleAnswer, title, subTitle, discussionUrn, lineOfBusiness, smartLink } = this.state;
    const actionData = {
      stimulus,
      question,
      sampleAnswer,
      title,
      subTitle,
      discussionUrn,
      lineOfBusiness,
      smartLink
    }
    this.props.actions.saveFormData(actionData)
    this.props.actions.generateHTML(actionData);
    history.push('/discussion-preview')
  }

  onCancel = () => {
    this.props.actions.clearFormData();
    history.push('/')
  }

 
  submitRender = () => {
    const { title, lineOfBusiness } = this.state
    return title && lineOfBusiness ? false : true
  }
  
  onHandleChange = (value) => {
	  this.setState({lineOfBusiness: value})
  }
 
 render() {
    const { stimulus, question, sampleAnswer, title, subTitle, allError, discussionUrn, isLoading, lineOfBusiness } = this.state;
    const { lineOfBusinesses } = this.props;
    const isLineOfBusinessDisabled = (discussionUrn != null && discussionUrn.length > 0) && lineOfBusiness && lineOfBusiness.length > 0 ;
    if (isLoading) return <Loader />
    return (
      <Fragment>
        <div className="discussion">
          <div className="discussion_container">
            <div className="discussion_container_section_title">
              <div className="discussion-id discussion_container_discidcontainer">
                <label className="input-box__label disc_id">Discussion ID</label>
                <p className="smallinput">{ discussionUrn }</p>
              </div>
              <div className="discussion-id discussion_container_lobcontainer">
              	<Dropdown dropDown={lineOfBusinesses} onHandleChange={this.onHandleChange} selectedTypeLabel={lineOfBusiness} label={"Line of Business*"} isDisabled={isLineOfBusinessDisabled}/>
              </div>		
              <InputField
                onChange={(e) => this.onChangeTextArea('title', e)}
                label={'Title*'}
                value={title}
                customClass="inputtitle"
                error={allError.title && !title ? 'Title should not be empty' : ''}
                config={{ name:'title', id:'title_id' }}
              />

            </div>
            <InputField
              onChange={(e) => this.onChangeTextArea('subTitle', e)}
              label={'Section Title'}
              value={subTitle}
              customClass="inputsection"
              config={{ name:'subTitle', id:'subTitle' }}
            />
            <div className="discussion_container_section_stimulus">
              <label className="input-box__label sim">Stimulus</label>
              <textarea className={`text-area ${allError.stimulus && !stimulus && 'text-area-error'}`}
                id="stimulus"
                name = "stimulus"
                onChange={(e) => this.onChangeTextArea('stimulus', e)}
                value={stimulus} />

            </div>
            <div className="discussion_container_section_prompt">
              <label className="input-box__label prom">Question prompt</label>
              <textarea className={`text-area ${allError.question && !question && 'text-area-error'}`}
                id="question"
                name = "question"
                onChange={(e) => this.onChangeTextArea('question', e)}
                value={question} />

            </div>
            <div className="discussion_container_section_answer">
              <label className="input-box__label ans">Sample answer</label>
              <textarea className={`text-area ${allError.sampleAnswer && !sampleAnswer && 'text-area-error'}`}
                onChange={(e) => this.onChangeTextArea('sampleAnswer', e)}
                id="sampleAnswer"
                name = "sampleAnswer"
                value={sampleAnswer} />

            </div>
            <div className="discussion_container_section_save">
              <Button onClick={this.onCancel} id="cancel-btn" type="button" text={'CANCEL'} className="grey-bg-outline" />
              <Button disabled={this.submitRender()} id="preview-btn" onClick={this.onPreview} type="submit" text={'PREVIEW'} className="grey-outline" />
              <Button disabled={this.submitRender()} id="submit-btn" onClick={this.onSave} type="submit" text={'SAVE'} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  formData: state.discussion,
  cdnUrl: state.discussion.cdnUrl,
  token: state.authStore.sso ? state.authStore.sso.tokenId : null,
  error: state.discussion.error,
  sso: state.discussion.sso,
  isSuccessfull: state.discussion.isSuccessfull,
  lineOfBusinesses: state.authStore.lineOfBusinesses
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...authoringDispatcher }, dispatch)
  };
}

export const DiscussionsComponent = withTranslate(DiscussionForm);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(DiscussionForm));