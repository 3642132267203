import axios from 'axios';

export default class AuthoringService {

    generateHTML = (payload) => {

      const customHeaders = {
        appversion: 'app',
        'accept-language': 'en-US,en;q=0.8'
      };

      return axios.post('/api/discussion/generateHTML', { body: payload, headers: customHeaders });
    }    

    fetchDiscussions = (ssotoken, lineOfBusinesses) => {
      return axios.post('/api/discussion/list', {lineOfBusinesses}, {headers: {ssotoken}})
    }

    submitDiscussion = (payload, ssotoken) => {
      return axios.put('/api/discussion/form/submit', payload, {headers: {ssotoken}})
    }

    saveDiscussion = (payload, ssotoken) => {
      return axios.put('/api/discussion/form/save', payload, {headers: {ssotoken}})
    }

    saveSmartLink = (payload, ssotoken) => {
    	return axios.put('/api/smartlink/update', payload, {headers: {ssotoken}});  
     }
}