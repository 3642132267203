import React, { Suspense, useEffect, useCallback, useState, useMemo } from 'react';
import Loader from './components/loader';
import { Provider } from 'react-redux';
import { StorageManager } from './utils/storage-manager';
import Routes from './routes';
import store from './store';
import AuthenticationService from './services/authenticationService';

export const AppContext = React.createContext(null);

const App = () => {

    const [token, setToken] = useState(null)
    const [config, setConfig] = useState(null);
    const authenticateSvc = new AuthenticationService();

    useEffect(() => {
        if (!config) {
            authenticateSvc.getConfig().then(response => {
                console.log('config response', response.data);
                setConfig(response.data);
                authenticateSvc.setConfig(response.data);
            })
        }
        if (config) {
            let redirectUrl = window.location.href;
            console.log('redirect url', `${config.auth.tokenUrl}${redirectUrl}`);
            if (!authenticateSvc.isAuthenticated()) {
                console.log('NOT AUTHENTICATED SO RELOGIN');
                console.log('redirecting to', `${config.auth.tokenUrl}${redirectUrl}`);
                authenticateSvc.redirect(`${config.auth.tokenUrl}${redirectUrl}`);
                //window.location.href = `${config.auth.tokenUrl}/redirect/auth?url=${redirectUrl}`
            } else {
                console.log('check validity token else');
                authenticateSvc.checkTokenValidity().then(response => {
                    console.log('check validity token', response);
                    if (response && response.data && response.data.data.valid === false) {
                        console.log('TOKEN IS INVALID', response);
                        authenticateSvc.redirect(`${config.auth.tokenUrl}${redirectUrl}`);
                        //window.location.href =  `${config.auth.tokenUrl}/redirect/auth?url=${redirectUrl}`
                    } else {
                        setToken(authenticateSvc.getCookie(config.auth.sessionHeader));
                    }
                })
            }
        }
    }, [config])

    const appContextValue = useMemo(() => {
        return {
            token,
            setToken,
        };
    }, [token, setToken]);

    StorageManager.setLocal('lng', 'en');
    if (!token) {
        return <Loader />;
    }
    return (
        <Suspense fallback={<Loader />}>
            <AppContext.Provider value={appContextValue}>
                <Provider store={store}>
                    <Routes token={token} config={config} />
                </Provider>
            </AppContext.Provider>
        </Suspense>
    );
}

export default App;