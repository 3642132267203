import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Router, Route, Switch } from 'react-router-dom';
import history from './history';
import {  publicRoutes } from './routes';
import { StorageManager } from '../utils/storage-manager';
import Layout from '../components/layout/index';
import Loader from '../components/loader';
import { AppContext } from '../App';
import * as authenticationDispatcher from '../actions/authenticationDispatcher';
import AuthenticationService from '../services/authenticationService';

class Routes extends React.PureComponent {


  componentDidMount(){
    this.props.actions.setToken( this.props.token );
  }

  logout(redirectUrl) {
    new AuthenticationService().logout();
    console.log('redirectUrl', redirectUrl);
    window.location.href = redirectUrl;
  }

  render(){

    const  selectedLang = StorageManager.getLocal('lng');

    let routes;

      routes = publicRoutes;

    return (
      <Router history={ history }>
        <Switch>
          { routes.map((route, index) => {
            const { component: RouteComponent, exact, path } = route;

            return (
              <Route
                exact={ exact }
                key={ index }
                path={ path }
                render={ (props) => (
                  <Layout { ...this.props }  logout={this.logout}>
                    <RouteComponent { ...props } selectedLang={ selectedLang }/>
                  </Layout>
                ) } />
            );
          })

          }
          <Route path="" render={ () => (<Redirect to="/" />) } />
        </Switch>
      </Router>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...authenticationDispatcher}, dispatch)
});

export default connect(null, mapDispatchToProps)(Routes);

