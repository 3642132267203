import { ACTION_TYPES } from './actionTypes';
import AuthoringServices from '../services/authoringServices';
import AuthenticationService from '../services/authenticationService';

export function saveFormData(action) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SAVE_FORM_DATA,
      payload: action
    })
  }
}

export function createURN(action) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CREATE_URN,
      payload: action
    })
  }
}

export function isLoadingUpdate(action) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.IS_LOADING,
      payload: action
    })
  }
}

export function generateHTML(payload) {
  return (dispatch) => {
    new AuthoringServices().generateHTML(payload).then(response => {
      dispatch({
        type: ACTION_TYPES.SET_GENERATED_HTML,
        payload: response.data
      })
    })
  }
}

export function clearFormData() {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CLEAR_FORM_DATA,
      payload: {}
    })
  }
}

export function getDiscussionList(token, lineOfBusinesses) {
  return (dispatch) => {
	let lineOfBusinessVals = lineOfBusinesses.map(item => item.type);
    new AuthoringServices().fetchDiscussions(token, lineOfBusinessVals).then((response) => {
      dispatch({
        type: ACTION_TYPES.SET_DISCUSSION_LIST,
        payload: response.data
      })
    }).catch((error) => {
      console.log('error', error);
      dispatch({
        type: ACTION_TYPES.DISCUSSION_LIST_FETCH_ERROR,
        payload: error.data
      })
    })
  }
}

export function saveSmartLink(payload, token) {
	return (dispatch) => {
		new AuthoringServices().saveSmartLink(payload, token).then(response => {
			dispatch({
				type: ACTION_TYPES.SMART_LINK_SAVED,
				payload
			})
		})
	}
}

export function closeSmartLinkSnackBar() {
  return {
    type: ACTION_TYPES.RESET_SMART_LINK_SAVED
  }
}

export function submitDiscussion(payload, token) {
  return (dispatch) => {
    console.log('payload', payload)
    new AuthoringServices().submitDiscussion(payload, token).then(async (response) => {
      console.log(" ==== ", response)
      await dispatch({
        type: ACTION_TYPES.DISCUSSION_SUBMITTED_SUCCESSFULL,
        payload: response.data
      })
      dispatch({
        type: ACTION_TYPES.UPDATE_DISCUSSION_LIST,
        payload: response.data
      })
    }).catch((error) => {
      dispatch({
        type: ACTION_TYPES.DISCUSSION_SUBMITTED_FAILED,
        payload: error
      })
    })
  }
}

export function saveDiscussion(payload, token) {
  return (dispatch) => {
    console.log('payload', payload)
    new AuthoringServices().saveDiscussion(payload, token).then(async (response) => {
      console.log('Save Discussion', response.data);
      await dispatch({
        type: ACTION_TYPES.DISCUSSION_SUBMITTED_SUCCESSFULL,
        payload: response.data
      })
      dispatch({
        type: ACTION_TYPES.SINGLE_DISCUSSION_UPDATE,
        payload: response.data
      })

    }).catch((error) => {
      dispatch({
        type: ACTION_TYPES.DISCUSSION_SUBMITTED_FAILED,
        payload: error
      })
    })
  }
}

export function resetFlag() {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.RESET_FLAG
    })
  }
}

export function resetTableRows() {
  return {
    type: ACTION_TYPES.SINGLE_DISCUSSION_UPDATE
  }
}

export function getAllLinesOfBusiness() {
  return (dispatch) => {
	new AuthenticationService().getLineOfBusiness().then (response => {
	  dispatch({
			type: ACTION_TYPES.SET_LINE_OF_BUSINESS,
			payload: response.data
	  });
	});
  }
	
}

