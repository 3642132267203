import axios from 'axios';
let config = {};

export default class AuthenticationService {

  getConfig = () => {
    return axios.get('/api/config');
  }

  redirect = (url) => {
    setTimeout(() => {
      window.location.href = url;
    }, 1000)
  }

  setConfig = (conf) => {
    config = conf.auth;
  }

  logout = () => {
    const ssoCookie = this.getCookie(config.sessionHeader);
    console.log('ssoCookie', ssoCookie);
    const headers = {};
    headers[config.sessionHeader] = ssoCookie;
    return axios.post('/api/token/logout', null, { headers });
  }

  isAuthenticated = () => {
    const ssoCookie = this.getCookie(config.sessionHeader);
    console.log(config.sessionHeader);
    console.log('ssoCookie', ssoCookie);
    return true;
  }

  checkTokenValidity = () => {
    const ssoCookie = this.getCookie(config.sessionHeader);
    console.log('ssoCookie', ssoCookie);
    const headers = {};
    headers[config.sessionHeader] = ssoCookie;
    return axios.get('/api/token/validity', { headers });
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    console.log('cookies', ca);
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    // if (name == config.sessionHeader) {
    //   return 'aydXI45gXEqKvZyaj2vgzegXpFo.*AAJTSQACMDIAAlNLABxhN2dDTWNpZTZJNjRqeVZMYTR5UmpENkdGcjA9AAR0eXBlAANDVFMAAlMxAAIwOQ..*';
    // }
    return null;
  }

  getLineOfBusiness = () => {
    const ssoCookie = this.getCookie(config.sessionHeader);
    const headers = {};
    headers[config.sessionHeader] = ssoCookie;
    return axios.get('/api/user/lineOfBusiness', { headers });
  }
}
